import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import SidebarHeader from "./SidebarHeader";
import SideMenuContent from "./sidemenu/SideMenuContent";
import PerfectScrollbar from "react-perfect-scrollbar";

const Sidebar = props => {
  const [state, setState] = useState({
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar
  });

  const updateWidth = () => {
    setState(s => ({ ...s, width: window.innerWidth }));
  };

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("resize", updateWidth, false);
    }

    return () => {
      window.removeEventListener("resize", updateWidth, false);
    };
  }, []);

  const changeActiveIndex = id => {
    setState(s => ({ ...s, activeIndex: id !== state.activeIndex ? id : null }));
  };

  const handleSidebarMouseEnter = id => {
    setState(s => ({ ...s, hoveredMenuItem: id !== state.hoveredMenuItem ? id : null }));
  };

  const handleActiveItem = url => {
    setState(s => ({ ...s, activeItem: url }));
  };

  let {
    visibilityState,
    toggleSidebarMenu,
    toggle,
    color,
    activeTheme,
    collapsed,
    activePath,
    sidebarState,
    currentLang,
    permission,
    currentUser,
    collapsedMenuPaths
  } = props;

  let {
    menuShadow,
    activeIndex,
    hoveredMenuItem,
    activeItem,
    ScrollbarTag
  } = state;

  return (
    <>
      <div className={classnames(`main-menu menu-fixed menu-light menu-accordion menu-shadow`,
        { collapsed: sidebarState === false, "hide-sidebar": visibilityState === false }
      )}
      >
        <SidebarHeader
          toggleSidebarMenu={toggleSidebarMenu}
          toggle={toggle}
          sidebarBgColor={color}
          sidebarVisibility={props.handleSidebarVisibility}
          activeTheme={activeTheme}
          collapsed={collapsed}
          menuShadow={menuShadow}
          activePath={activePath}
          sidebarState={sidebarState}
        />
        <ScrollbarTag
          className={classnames("main-menu-content", {
            "overflow-hidden": ScrollbarTag !== "div",
            "overflow-scroll": ScrollbarTag === "div"
          })}
        >
          <ul className="navigation navigation-main">
            <SideMenuContent
              setActiveIndex={changeActiveIndex}
              activeIndex={activeIndex}
              hoverIndex={hoveredMenuItem}
              handleSidebarMouseEnter={handleSidebarMouseEnter}
              activeItemState={activeItem}
              handleActiveItem={handleActiveItem}
              activePath={activePath}
              lang={currentLang}
              permission={permission}
              currentUser={currentUser}
              collapsedMenuPaths={collapsedMenuPaths}
              toggleMenu={props.handleSidebarVisibility}
              deviceWidth={props.deviceWidth}
            />
          </ul>
          {process.env.REACT_APP_VERSION_NUMBER && <small className="text-center d-block my-2">Version {process.env.REACT_APP_VERSION_NUMBER}</small>}
        </ScrollbarTag>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user
  };
};

export default connect(mapStateToProps)(Sidebar);
