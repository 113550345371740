import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../../assets/scss/pages/app-email.scss";
// import Footer from './Footer';
import { init, cancel } from '../../services/app';
import NavbarBasic from './BasicNavbar';
import Sidebar from './Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';

const AppContainer = props => {
    const [size, setSize] = useState([0, 0]);
    const [settings, setSettings] = useState();
    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [windowShrink, setWindowShrink] = useState(false);
    const [prevLocation, setPrevLocation] = useState('/');
    const location = useLocation();

    useEffect(() => {
        setMounted(true);

        const start = async () => {
            const data = await init();
            setSettings(data?.settings);
        };

        start();

        return () => {
            cancel();
            setMounted(false);
        };
    }, []);

    useEffect(() => {
        if (settings?.freshdesk_widget_id) {
            const script1 = document.createElement('script');
            script1.text = `window.fwSettings={
                'widget_id':${settings.freshdesk_widget_id}
                };
                !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

            document.body.appendChild(script1);


            const script2 = document.createElement('script');

            script2.src = `https://widget.freshworks.com/widgets/${settings.freshdesk_widget_id}.js`;
            script2.async = true;
            script2.defer = true;
          
            document.body.appendChild(script2);
          
            return () => {
                document.body.appendChild(script1);
                document.body.removeChild(script2);
            };
        }
    }, [settings]);

    useLayoutEffect(() => {
        function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (size[0] > 1200) {
            setSidebarHidden(true);
            setWindowShrink(false);
        } else {
            setWindowShrink(true);
        }
    }, [size, sidebarHidden]);

    useEffect(() => {
        if (windowShrink) {
            setSidebarHidden(false);
        }
    }, [windowShrink]);

    useEffect(() => {
       if (location.pathname !== prevLocation) {
           setSidebarHidden(false);
           setPrevLocation(location.pathname);
       }
    }, [location, prevLocation]);

    const handleSidebarVisibility = () => {
        if (mounted) {
          setSidebarHidden(!sidebarHidden);
        }
    };

    return (
        <div className='wrapper vertical-layout theme-primary navbar-floating'>
            <Sidebar handleSidebarVisibility={handleSidebarVisibility} visibilityState={sidebarHidden} />
            <div className='app-content content'>
                <NavbarBasic handleSidebarVisibility={handleSidebarVisibility} />
                <div className="content-wrapper">
                    {props.children}
                    {/* <Footer /> */}
                </div>
            </div>
        </div>
    );
};

export default AppContainer;