import authReducer from './auth';
import brandReducer from './brand';
import loadingReducer from './loading';
import notifications from './notifications';
import {combineReducers, createStore} from 'redux';

const rootReducers = combineReducers({
  auth: authReducer,
  brand: brandReducer,
  loading: loadingReducer,
  notifications,
});

const store = createStore(rootReducers);

export default store;