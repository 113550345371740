import React from "react";
import { connect } from 'react-redux';
import logo from "../../../assets/img/logo/logo.png";
import "../../../assets/scss/components/app-loader.scss";

const SpinnerComponent = (props) => {
  return (
    <div>
      {props.loading &&
        <div className="fullscreen-spinner vh-100 position-fixed  w-100">
          <img className="fallback-logo" src={logo} alt="logo" />
          <div className="loading">
            <div className="effect-1 effects"></div>
            <div className="effect-2 effects"></div>
            <div className="effect-3 effects"></div>
          </div>
        </div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return ({ loading: state.loading.loading });
};

export default connect(mapStateToProps)(SpinnerComponent);

