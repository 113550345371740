import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

export const CancelToken = axios.CancelToken;
export let cancelList;
export let cancelGetTypes;
export let cancelGetByUser;
export let cancelUnread;

export const list = async (options) => {
  const { sort, direction, filters, results, offset } = options;

  const body = {
    sort,
    direction,
    filters
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/notifications/getAll?results=${results}&offset=${offset}`,
      body,
      { cancelToken: new CancelToken(function executor(c) { cancelList = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  }
};

export const getUnread = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications/unread`,
      { cancelToken: new CancelToken(function executor(c) { cancelUnread = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    return err?.response?.data;
  }
};

export const getByUser = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications/`,
      { cancelToken: new CancelToken(function executor(c) { cancelGetByUser = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    return { notifications: [] };
  }
};

export const markAsRead = async (id) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/notifications/read`, { id });

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    return err.response.data;
  }
};

export const getTypes = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications/getTypes`,
      { cancelToken: new CancelToken(function executor(c) { cancelGetTypes = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  }
};

export const getStatistics = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications/getStatistics`,
      { cancelToken: new CancelToken(function executor(c) { cancelGetTypes = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  }
};

