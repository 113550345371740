import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelList;


export const init = async () => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/app/init`;

    try {
        const request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        if (!request?.data?.user?.active) {
            Store.dispatch({ type: 'LOGOUT' });
            return false;
        }

        if (request?.data?.brand?.name) {
            document.title = `COI | ${request.data.brand.name}`;
        }

        Store.dispatch({ type: 'USER_DATA', user: {...request.data.user, shortcuts: request.data?.shortcuts} });
        Store.dispatch({ type: 'BRAND_DATA', payload: request.data.brand });
        Store.dispatch({ type: 'LOADED' });

        return request.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response?.data?.message?.name === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};