import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "feed",
    title: "Novedades",
    type: "item",
    icon: <Icon.Rss size={20} />,
    navLink: "/feed",
    filterBase: "/feed"
  },
  {
    id: "files",
    title: "Archivos",
    type: "item",
    icon: <Icon.File size={20} />,
    navLink: "/files",
    filterBase: "/files"
  },
  {
    type: "groupHeader",
    groupTitle: "Informes",
    permissions: ["commercial_reports_read"],
  },
  {
    id: "commercial",
    title: "Informe Comercial",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["commercial_reports_read"],
    navLink: "/report/:filter",
    filterBase: "/report/commercial"
  },
  {
    type: "groupHeader",
    groupTitle: "Administración",
    permissions: ["contents_read", "shortcuts_read", "events_read", "surveys_read", "surveys_manage", "surveys_write"],
  },
  {
    id: "contents",
    title: "Contenidos",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: ["contents_read"],
    navLink: "/contents",
    filterBase: "/contents"
  },
  {
    id: "shortcuts",
    title: "Atajos",
    type: "item",
    icon: <Icon.Link size={20} />,
    permissions: ["shortcuts_read"],
    navLink: "/shortcuts",
    filterBase: "/shortcuts"
  },
  {
    id: "events",
    title: "Eventos",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["events_read"],
    navLink: "/events",
    filterBase: "/events"
  },
  {
    type: "groupHeader",
    groupTitle: "Auditorías",
    permissions: ["surveys_read"],
  },
  {
    id: "surveys",
    title: "Gestionar",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["surveys_read"],
    navLink: "/surveys/manage",
    filterBase: "/surveys/manage",
  },
  {
    id: "surveys/completed",
    title: "Completadas",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ["surveys_read"],
    navLink: "/surveys/completed",
    filterBase: "/surveys/completed",
  },
  {
    id: "fill_survey",
    title: "Mis Auditorías",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["surveys_write"],
    navLink: "/surveys/fill",
    filterBase: "/surveys/fill",
  },
  {
    type: "groupHeader",
    groupTitle: "Analytics",
    permissions: ["emails_read", "notifications_read"],
  },
  {
    id: "emails",
    title: "Emails",
    type: "item",
    icon: <Icon.Mail size={20} />,
    permissions: ["emails_read"],
    navLink: "/emails",
    filterBase: "/emails"
  },
  {
    id: "notifications",
    title: "Notificaciones",
    type: "item",
    icon: <Icon.Bell size={20} />,
    permissions: ["notifications_read"],
    navLink: "/notifications/list",
    filterBase: "/notifications/list"
  },
  {
    type: "groupHeader",
    groupTitle: "Configuración",
    permissions: ["users_read", "profiles_read", "branches_read", "roles_read"],
  },
  {
    id: "users",
    title: "Usuarios",
    type: "item",
    permissions: ["users_read"],
    icon: <Icon.User size={20} />,
    navLink: "/users",
    filterBase: "/users"
  },
  {
    id: "profiles",
    title: "Perfiles",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["profiles_read"],
    navLink: "/profiles",
    filterBase: "/profiles"
  },
  {
    id: "roles",
    title: "Roles",
    type: "item",
    icon: <Icon.Award size={20} />,
    permissions: ["roles_read"],
    navLink: "/roles",
    filterBase: "/roles"
  },
  {
    id: "branches",
    title: "Sucursales",
    type: "item",
    permissions: ["branches_read"],
    icon: <Icon.MapPin size={20} />,
    navLink: "/branches",
    filterBase: "/branches"
  },
  {
    id: "settings",
    title: "Configuración",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ['settings_read'],
    navLink: "/settings",
    filterBase: "/settings",
  },
  {
    type: "groupHeader",
    groupTitle: "SOPORTE",
    permissions: null,
  },
  {
    id: "videotutoriales",
    title: "Videotutoriales",
    type: "item",
    icon: <Icon.Youtube size={20} />,
    permissions: null,
    navLink: "/tutorials",
    filterBase: "/tutorials"
  },
  
  /*{
    id: "docs",
    title: "Manual de Usuario",
    icon: <Icon.Clipboard size={20} />,
    type: "external-link",
    newTab: true,
    permissions: null,
    navLink: "https://carbon-base.com/"
  },
  {
    id: "slack",
    title: "Slack",
    icon: <Icon.Slack size={20} />,
    type: "external-link",
    newTab: true,
    permissions: null,
    navLink: "https://carbon-base.com/"
  },*/
];

export default navigationConfig;
