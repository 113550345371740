import React, { useEffect } from "react";
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import {
    Navbar,
    UncontrolledDropdown,
    // Badge,
    NavLink as RNavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
    UncontrolledButtonDropdown,
    Badge,
} from "reactstrap";
import {
    Bell,
    ChevronDown,
    ExternalLink,
    Menu,
    Power,
    User,
} from "react-feather";
import swal from 'sweetalert2';
import withRouter from '../withRouter';
import { NavLink } from "react-router-dom";
import { cancelUnread, getUnread } from "../../services/notifications";

const NavbarBasic = (props) => {
    const signout = () => {
        swal.fire({
            title: "Cerrar Sesión",
            text: "Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Cerrar Sesión',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger ml-2",
                cancelButton: "btn btn-secondary active mr-2",
            }
        }).then(
            (value) => {
                if (value.isConfirmed) {
                    props.logout();
                    props.router.navigate('');
                }
            }
        );
    };

    useEffect(() => {
        const getNotifications = async () => {
            const res = await getUnread();

            if (res?.success) {
                props.setUnread(res.unread);
            }
        };
        getNotifications();

        return () => {
            if (cancelUnread) {
                cancelUnread();
            }
        };
    }, [props]);

    return (
        <Navbar className="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
            <div className="navbar-wrapper">
                <div className="navbar-container content">
                    <div className="navbar-collapse" id="navbar-mobile">
                        <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                            <ul className="navbar-nav d-xl-none">
                                <NavItem className="mobile-menu mr-auto">
                                    <RNavLink
                                        className="nav-menu-main menu-toggle hidden-xs is-active"
                                        onClick={props.handleSidebarVisibility}
                                    >
                                        <Menu className="ficon" />
                                    </RNavLink>
                                </NavItem>
                            </ul>
                            <ul className="nav navbar-nav bookmark-icons">
                                {props?.user?.shortcuts?.length > 0 &&
                                    <UncontrolledButtonDropdown className="ml-2" direction="down">
                                        <DropdownToggle color="primary" caret>
                                            <span className="d-none d-md-inline">Accesos</span>
                                            <ExternalLink className="d-inline d-md-none" size={15} />
                                            <ChevronDown size={15} className="d-none d-md-inline" />
                                            <ChevronDown size={15} color="primary" className="d-inline d-md-none" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {props.user.shortcuts.map((shortcut, idx) => (
                                                <div key={shortcut._id} >
                                                    <DropdownItem header>{shortcut._id}</DropdownItem>
                                                    {shortcut.links.map((link, index) => (
                                                        <DropdownItem key={`${shortcut._id}-link-${index}`} onClick={() => window.open(link.url)}>
                                                            {link.name}
                                                        </DropdownItem>
                                                    ))}
                                                    {props?.user?.shortcuts?.length !== (idx + 1) && <DropdownItem divider />}
                                                </div>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                }
                            </ul>
                        </div>
                        <ul className="nav navbar-nav float-right">
                            <li className="nav-item">
                                <NavLink to="/notifications" className="nav-link nav-link-label position-relative bg-white" >
                                    <Bell size={21} />
                                    <Badge pill color="primary" className="badge-up">{props.unread || null}</Badge>
                                </NavLink>
                            </li>
                            <UncontrolledDropdown
                                tag="li"
                                id="user-dropdown"
                                className="dropdown-user nav-item"
                            >
                                <DropdownToggle
                                    tag="a"
                                    data-toggle="dropdown"
                                    className="nav-link dropdown-user-link"
                                >
                                    <div className="user-nav d-sm-flex d-none">
                                        <span className="user-name text-bold-600">
                                            {props.user.name}
                                        </span>
                                        <span className="user-status">{props.user.brand}</span>
                                    </div>
                                    <span>
                                        {props?.user?.profile_pic_thumbnail ? <img
                                            src={props.user.profile_pic_thumbnail}
                                            className="round"
                                            height="40"
                                            width="40"
                                            alt="avatar"
                                        /> : <Avatar round={true} name={props.user.name} size="40" />
                                        }
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem tag="a" href="/profile">
                                        <User size={14} className="mr-50" />
                                        <span className="align-bottom">
                                            Editar Perfil
                                        </span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={signout}>
                                        <Power size={14} className="mr-50" />
                                        <span>Cerrar Sesión</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ul>
                    </div>
                </div>
            </div>
        </Navbar >
    );
};

const mapStateToProps = (state) => {
    return ({ user: state.auth.user, unread: state.notifications });
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: 'LOGOUT' }),
    setUnread: (unread) => dispatch({ type: 'SET', unread }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavbarBasic));
