import 'bootstrap/dist/css/bootstrap.css';
import "./index.scss";
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Error500 from './components/assets/errors/Error500';
import Spinner from "./components/assets/spinner/Fallback-spinner";
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ErrorBoundary fallbackRender={Error500}>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </ErrorBoundary>
  </BrowserRouter>
);

serviceWorkerRegistration.unregister();
